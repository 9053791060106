
import { Component, Prop, Ref } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import RateCard from '@/models/RateCard'
import { currencyMask, percentageMask } from '@/models/interface/Masks'
import CheckboxInput from '@/components/CheckboxInput/index.vue'

@Component({
  components: {
    Widget,
    FormInput,
    CheckboxInput,
  },
})
export default class RateCardHome extends ViewModel {
  public rate: number = 45

  public custom = {
    vcr: 95,
    ctv: 100,
    live: 100,
  }

  public audience: boolean = false

  public foot: boolean = false

  public result: any = null

  public rate_options = ['vcr', 'ctv', 'live', 'balanced', 'custom']

  public get masks() {
    return {
      percentageMask,
      currencyMask,
    }
  }

  public get rules() {
    const rate_card = new RateCard()
    return {
      rate: `required|min_value:${rate_card.DEFAULTS.MIN_RATE}`,
      vcr: `required|min_value:${rate_card.DEFAULTS.VCR.MIN_PERCENTAGE.times(
        100,
      ).toNumber()}|max_value:${rate_card.DEFAULTS.VCR.MAX_PERCENTAGE.times(100).toNumber()}`,
      ctv: `required|min_value:${rate_card.DEFAULTS.CTV.MIN_PERCENTAGE.times(100).toNumber()}`,
      live: 'required',
    }
  }

  public getTitle(key: string) {
    if (key === 'vcr') {
      return 'Best VCR'
    }

    if (key === 'ctv') {
      return 'Best CTV'
    }

    if (key === 'live') {
      return 'Best Live'
    }

    if (key === 'balanced') {
      return 'Balanced'
    }

    if (key === 'custom') {
      return 'Custom'
    }
  }

  public run() {
    const rate_card = new RateCard()

    this.result = rate_card.getRates(this.rate, this.audience, this.foot, this.custom)
  }

  public use(rate: any) {
    this.$router.push({
      name: 'MediaPlan',
      query: { audience: this.audience ? 1 : 0, foot_traffick: this.foot ? 1 : 0, ...rate },
    })
  }
}
